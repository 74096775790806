import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/_alert';
import { DataShare } from 'src/app/common/datashare';
import { MainService } from 'src/app/common/main.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../shared/loader/loader.service';
import { FormValidation } from 'src/app/common/formValidation';

@Component({
  selector: 'app-bap-information',
  templateUrl: './bap-information.component.html',
  styleUrls: ['./bap-information.component.css'],
})
export class BapInformationComponent {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private mainService: MainService,
    private dataShare: DataShare,
    private alertService: AlertService,
    public loaderService: LoaderService,
    public formValidation: FormValidation
  ) {}

  @ViewChild('moveTop') moveTop!: ElementRef;
  selected: number = 0;
  producerInfoForm!: FormGroup;
  producerLoginForm!: FormGroup;
  showPassword: boolean = false;
  submitted: boolean = false;
  step1Submitted: boolean = false;
  passwordLenth: number = 0;
  jwtToken: any;
  isSubmitButtonDisabled1 = true;
  isSubmitButtonDisabled2 = true;
  displayErrorMsg!: string;
  validateErrorMsg: boolean = false;
  validateResp: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
    timeout: 30000,
  };
  mustHaveValidation = [
    { name: 'minAndMax', message: '8-40 characters', selected: false },
    {
      name: 'upperCase',
      message: 'An uppercase letter',
      selected: false,
    },
    {
      name: 'lowerCase',
      message: 'A lowercase letter',
      selected: false,
    },
    {
      name: 'number',
      message: 'A number',
      selected: false,
    },
    {
      name: 'specialCharacter',
      message: 'A special character',
      terms: '~ ! @ # $ % ^ & * ( ) [ { }',
      selected: false,
    },
  ];
  cannotHaveValidation = [
    {
      name: 'sequentialCharacters',
      message: 'More than two sequential characters',
      terms: 'Like 123 or ABC',
      selected: false,
    },
    {
      name: 'repeatingCharacters',
      message: 'More than two repeated characters',
      terms: 'Like 111 or AAA',
      selected: false,
    },
    {
      name: 'month',
      message: 'Abbreviations for months',
      terms: 'Like Jan, Feb, or Mar',
      selected: false,
    },
    {
      name: 'day',
      message: 'Abbreviations for days',
      terms: 'Like Mon, Tue, or Wed',
      selected: false,
    },
    {
      name: 'restricted',
      message: 'Restricted terms',
      terms:
        'Appl, Asdf, Basic, Cadam, Demo, Focus, Game, Net, New, Pas, Ros, Sign, Sys, Tso, Valid, Vtam, Log',
      selected: false,
    },
    {
      name: 'specialCharacter',
      message: 'A special character',
      terms: '= _ - + ',
      selected: false,
    },
  ];

  errorMessages: any = {
    required: () => 'is required',
    minlength: (error: any) =>
      `must be at least ${error.requiredLength} characters`,
    maxlength: (error: any) =>
      `must not exceed ${error.requiredLength} characters`,
    pattern: () => 'Invalid format',
  };

  ngOnInit() {
    // this.fetchJWTToken();
    this.producerInfoForm = this.formBuilder.group({
      producerId: ['', [Validators.required, Validators.minLength(9)]],
      producerNPN: ['', [Validators.required]],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern('^[0-9]+$'),
        ],
      ],
    });
    this.producerLoginForm = this.formBuilder.group({
      userId: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(254),
        Validators.pattern(/^[a-zA-Z0-9.]+$/),
        Validators.pattern(/^[^/]*$/), // Ensures that '/' is not included
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*(){}\[\]]).+$/
        ),
      ]),
    });
  }

  // fetchJWTToken(){
  //   this.mainService.getJWTTokenData().subscribe((jwtRes: any) =>{
  //   this.jwtToken = jwtRes;
  //   })
  // }
  // convenience getter for easy access to form fields
  get regForm(): { [key: string]: AbstractControl } {
    return this.producerInfoForm.controls;
  }

  get regForm2(): { [key: string]: AbstractControl } {
    return this.producerLoginForm.controls;
  }

  changeStep(newStep: number) {
    // Clear the alert whenever the step changes
    this.closeAlert();
    this.alertService.clear();
    // Logic to change the step
    this.selected = newStep;
  }

  getErrMsgForProducerInfo(controlName: string): string {
    return this.formValidation.getErrorMessage(
      controlName,
      this.producerInfoForm
    );
  }

  getErrMsgForProducerLogin(controlName: string): string {
    let errorMessage = this.formValidation.getErrorMessage(
      controlName,
      this.producerLoginForm
    );
    return errorMessage
      .replace(/userId/g, 'Username')
      .replace(/password/g, 'Password');
  }

  onSubmit() {
    this.step1Submitted = true;
    if (this.producerInfoForm.valid) {
      this.validateProducer();
    }
  }

  onReturnToLogin() {
    this.closeAlert();
    this.alertService.clear();
    this.router.navigate(['/producer/login']);
  }

  validateProducer() {
    // this.validateResp = {
    //   "firstName": "KYLE",
    //   "lastName": "GORDON",
    //   "type": "PR - Producer",
    //   "state": "TX",
    //   "workPhone": "2108605311",
    //   "mobilePhone": "",
    //   "emailAddress": "donotreply_pa@bcbstx.com",
    //   "contactId": "12345678895755765"
    // }
    const trimmedFormValues = Object.keys(this.producerInfoForm.value).reduce(
      (acc: any, key) => {
        acc[key] =
          typeof this.producerInfoForm.value[key] === 'string'
            ? this.producerInfoForm.value[key].trim()
            : this.producerInfoForm.value[key];
        return acc;
      },
      {}
    );
    console.log('producer details', trimmedFormValues);
    this.loaderService.show();
    this.mainService
      .verifyProducerDetails(trimmedFormValues)
      .subscribe((verifyRes: any) => {
        console.log(verifyRes, 'verifyRes');
        this.loaderService.hide();
        if (!(verifyRes instanceof HttpErrorResponse)) {
          if (verifyRes.producerStatus.status == 'Success') {
            this.validateResp = verifyRes.producerApiResp[0];
            this.changeStep(1);
            console.log('validateResp', this.validateResp);
          } else {
            this.alertService.error(
              `<span class="circle-exclamation-icon"></span>` +
                verifyRes.producerStatus.message,
              this.options
            );
          }
        } else {
          this.alertService.error(
            `<span class="circle-exclamation-icon"></span>` +
              verifyRes.error.error,
            this.options
          );
        }
      });
  }

  onLoginSubmit() {
    this.submitted = true;
    if (this.producerLoginForm.valid) {
      this.createLogin();
    }
  }

  createLogin() {
    let touAcceptedDate = this.dataShare.getTouAccepted();
    let versionId = this.dataShare.getVersionId();
    if (touAcceptedDate === undefined) {
      touAcceptedDate = new DatePipe('en-US').transform(
        new Date(),
        'MM-dd-yyyy'
      );
      console.debug('Tou came undefined : ' + touAcceptedDate);
    }
    let producerform = this.producerLoginForm.value;
    let userId = this.producerLoginForm.value.userId.trim();
    const url = environment.REGISTER_URL_BAP;

    this.loaderService.show();
    this.mainService.postDataByUrl({}, url).subscribe((response: any) => {
      console.log(response);
      let reqobj: any = {
        ...response,
      };
      var appId = environment.BAP_APP_ID;
      console.log(appId);
      reqobj?.callbacks.forEach((callback: any) => {
        if (callback.input.find((ip: any) => ip.name === 'IDToken1')) {
          callback.input.find((ip: any) => ip.name === 'IDToken1').value =
            userId;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken2')) {
          callback.input.find((ip: any) => ip.name === 'IDToken2').value =
            producerform.password.trim();
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken3')) {
          callback.input.find((ip: any) => ip.name === 'IDToken3').value =
            this.validateResp.firstName;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken4')) {
          callback.input.find((ip: any) => ip.name === 'IDToken4').value =
            this.validateResp.lastName;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken5')) {
          callback.input.find((ip: any) => ip.name === 'IDToken5').value =
            this.validateResp.emailAddress;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken6')) {
          callback.input.find((ip: any) => ip.name === 'IDToken6').value =
            appId;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken7')) {
          let appRole;
          appRole = environment.BAP_ROLE;
          console.log(appRole);
          callback.input.find(
            (ip: any) => ip.name === 'IDToken7'
          ).value = `[{\"hcscAppID\": \"${environment.BAP_APP_ID}\", \"hcscAppRole\": [\"Future State Agent\"]}]`;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken8')) {
          let corpcode = environment.CORP_CODE;
          callback.input.find(
            (ip: any) => ip.name === 'IDToken8'
          ).value = `{\"termsOfService\":{\"hcscAppID\":\"${environment.BAP_APP_ID}\",\"corpCode\":\"${corpcode}\",\"acceptedDate\":\"${touAcceptedDate}\",\"version\":\"${versionId}\"}}`;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken9')) {
          callback.input.find((ip: any) => ip.name === 'IDToken9').value =
            environment.CORP_CODE;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken10')) {
          let hcscOrganizationID = this.producerInfoForm.value.producerNPN;
          callback.input.find((ip: any) => ip.name === 'IDToken10').value =
            hcscOrganizationID;
        }

        //bind contactId which is coming from validate  producerAPI response
        // Bind this.validateResp.contactId
        if (callback.input.find((ip: any) => ip.name === 'IDToken11')) {
          callback.input.find((ip: any) => ip.name === 'IDToken11').value =
            this.validateResp.contactId;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken12')) {
          let profileID = environment.PROFILE_ID;
          callback.input.find((ip: any) => ip.name === 'IDToken12').value =
            profileID;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken13')) {
          callback.input.find((ip: any) => ip.name === 'IDToken13').value =
            this.validateResp.zipCode;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken14')) {
          callback.input.find((ip: any) => ip.name === 'IDToken14').value =
            this.validateResp.mobilePhone;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken15')) {
          callback.input.find((ip: any) => ip.name === 'IDToken15').value =
            this.validateResp.mobilePhone;
        }
        if (callback.input.find((ip: any) => ip.name === 'IDToken16')) {
          callback.input.find((ip: any) => ip.name === 'IDToken16').value =
            this.validateResp.mobilePhone;
        }
      });
      this.mainService.postDataByUrl(reqobj, url).subscribe((res: any) => {
        if (res['successUrl'] && res['successUrl'].length > 0) {
          /// this.blockUI.stop();
          this.changeStep(2);
          this.alertService.success('Success', this.options);
          this.loaderService.hide();
        } else {
          // this.blockUI.stop();
          let result = res?.callbacks[0]?.type;
          if (result === 'ValidatedCreateUsernameCallback') {
            let result1 = res.callbacks.filter(
              (t: any) => t.type === 'ValidatedCreateUsernameCallback'
            );
            let step1 = result1[0]?.output[1]?.value;
            let step2;
            if (step1.length > 0) {
              step2 = JSON.parse(step1);
            }
            if (step2?.policyRequirement === 'UNIQUE') {
              // this.blockUI.stop();
              this.alertService.error('User Already Exists', this.options);
            } else {
              // this.blockUI.stop();
              this.alertService.error('Password is not allowed', this.options);
            }
            this.loaderService.hide();
          } else if (result === 'TextOutputCallback') {
            this.loaderService.hide();
            let value = JSON.parse(res?.callbacks[0]?.output[0]?.value);
            if (
              value?.policyRequirements[0]?.policyRequirement ===
              'CONSECUTIVE_DIGITS_NOT_ALLOWED'
            ) {
              this.alertService.error(
                'Consecutive digits not allowed',
                this.options
              );
            } else if (
              value?.policyRequirements[0]?.policyRequirement ===
              'SEQ_CHARS_NOT_ALLOWED'
            ) {
              this.alertService.error(
                'More than two sequential characters are not allowed',
                this.options
              );
            } else if (
              value?.policyRequirements[0]?.policyRequirement ===
              'CHARS_NOT_ALLOWED'
            ) {
              this.alertService.error(
                'Password cannot contain certain characters',
                this.options
              );
            } else if (
              value?.policyRequirements[0]?.policyRequirement ===
              'REPEATED_CHARS_NOT_ALLOWED'
            ) {
              this.alertService.error(
                'More than 2 consecutive characters not allowed',
                this.options
              );
            } else {
              this.alertService.error('Password is not allowed', this.options);
            }
          } else if (result === 'NameCallback') {
            res?.callbacks?.forEach((callback: any) => {
              if (callback.input.find((ip: any) => ip.name === 'IDToken1')) {
                let producerID = this.producerInfoForm.value.producerId;
                console.log('producerID', producerID);
                callback.input.find((ip: any) => ip.name === 'IDToken1').value =
                  producerID;
              }
            });
            this.mainService
              .postDataByUrl(res, url)
              .subscribe((finalRes: any) => {
                this.loaderService.hide();
                if (
                  finalRes['successUrl'] &&
                  finalRes['successUrl'].length > 0
                ) {
                  /// this.blockUI.stop();
                  this.changeStep(2);
                  this.alertService.success('Success', this.options);
                }
                console.log('finalRes');
              });
          } else {
            this.loaderService.hide();
            this.alertService.error(res['message'], this.options);
          }
        }
      });
    });
  }

  backtoStep1() {
    this.changeStep(0);
  }

  backtoStep2() {
    this.changeStep(1);
  }

  passwordChange(event: any) {
    this.passwordLenth = event.length;
    //Validate minimum length

    if (event.length >= 8) {
      this.mustHaveValidation[0].selected = true;
    } else {
      this.mustHaveValidation[0].selected = false;
    }
    //Validate capital latters
    var CapitalLetters = /[A-Z]/g;
    if (event.match(CapitalLetters)) {
      this.mustHaveValidation[1].selected = true;
    } else {
      this.mustHaveValidation[1].selected = false;
    }
    //Validate lowercase latters
    var lowerCaseLetters = /[a-z]/g;
    if (event.match(lowerCaseLetters)) {
      this.mustHaveValidation[2].selected = true;
    } else {
      this.mustHaveValidation[2].selected = false;
    }

    //Validate number
    var numbers = /[0-9]/g;
    if (event.match(numbers)) {
      this.mustHaveValidation[3].selected = true;
    } else {
      this.mustHaveValidation[3].selected = false;
    }
    //Validate Special charector
    var numbers = /[~!@#$%^&*()[{}]/;
    if (event.match(numbers)) {
      this.mustHaveValidation[4].selected = true;
    } else {
      this.mustHaveValidation[4].selected = false;
    }

    //can't have validations
    //sequentials
    let str = '~ ! @ # $ % ^ & * ( ) [ { }';

    for (const character in event) {
      if (
        String.fromCharCode(event.charCodeAt(character) + 1) ===
          event[+character + 1] &&
        !str.includes(event[character]) &&
        String.fromCharCode(event.charCodeAt(character) + 2) ===
          event[+character + 2]
      ) {
        this.cannotHaveValidation[0].selected = true;
      } else {
        this.cannotHaveValidation[0].selected = false;
      }
    }
    if ((this.cannotHaveValidation[0].selected = false)) {
      for (const character in event) {
        if (
          +event[+character + 1] === +event[character] + 1 &&
          +event[+character + 2] === +event[character] + 2
        ) {
          this.cannotHaveValidation[0].selected = true;
        } else {
          this.cannotHaveValidation[0].selected = false;
        }
      }
    }
    if (/(.)\1\1/.test(event)) {
      this.cannotHaveValidation[1].selected = true;
    } else {
      this.cannotHaveValidation[1].selected = false;
    }

    // Check for sequential numerical characters
    var test = (x: any) => !isNaN(x);
    var check = (x: any, y: any, i: any) => x + i === y;

    for (var i = 0; i < event.length - 2; i++) {
      if (test(event[i])) {
        if (test(event[i + 1]) && test(event[i + 2])) {
          if (
            check(Number(event[i]), Number(event[i + 1]), 1) &&
            check(Number(event[i]), Number(event[i + 2]), 2)
          ) {
            this.cannotHaveValidation[0].selected = true;
          } else {
          }
        }
      } else if (!test(event[i + 1]) && !test(event[i + 2])) {
        if (
          check(event.charCodeAt(i), event.charCodeAt(i + 1), 1) &&
          check(event.charCodeAt(i), event.charCodeAt(i + 2), 2)
        ) {
          this.cannotHaveValidation[0].selected = true;
        }
      }
    }
    let monthsAbb = moment.monthsShort();

    if (
      monthsAbb.find((month) => event.toUpperCase().match(month.toUpperCase()))
    ) {
      this.cannotHaveValidation[2].selected = true;
    } else {
      this.cannotHaveValidation[2].selected = false;
    }
    let daysAbb = Array.apply(null, Array(7)).map(function (_, i) {
      return moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format('ddd');
    });

    if (daysAbb.find((day) => event.toUpperCase().match(day.toUpperCase()))) {
      this.cannotHaveValidation[3].selected = true;
    } else {
      this.cannotHaveValidation[3].selected = false;
    }
    let words: string[] = [
      'SYS|LOG|TSO|PAS|NEW|NET|ROS|XXX|SIGN|VTAM|DEMO|ASDF|1234|BASIC|FOCUS|CADAM|VALID|GAME|APPL',
    ];
    words = words[0].split('|');
    if (
      words.find((restricted: any) =>
        event.toUpperCase().match(restricted.toUpperCase())
      )
    ) {
      this.cannotHaveValidation[4].selected = true;
    } else {
      this.cannotHaveValidation[4].selected = false;
    }
    //Validate Special character
    let str1 = '_  -+  =';
    for (const character in event) {
      if (str1.includes(event[character])) {
        this.cannotHaveValidation[5].selected = true;
      } else {
        this.cannotHaveValidation[5].selected = false;
      }
    }
    this.isSubmitButtonDisabled1 = !this.mustHaveValidation.every(
      (x) => x.selected
    );
    this.isSubmitButtonDisabled2 = this.cannotHaveValidation.some(
      (x) => x.selected
    );
  }

  closeAlert() {
    this.validateErrorMsg = false;
  }

  resendEmail() {
    const { CORP_CODE, BAP_APP_ID, BAP_RESEND_RESEND_Verification_Email } =
      environment;

    let reqobj: any = {
      userName: this.producerLoginForm.value.userId.trim(),
      appName: BAP_APP_ID,
      corpCode: CORP_CODE,
      resendVerification: true,
    };

    let url =
      BAP_RESEND_RESEND_Verification_Email +
      '/custom/resend-verification-email';
    this.loaderService.show();
    this.mainService.postApiCall(reqobj, url).subscribe((response: any) => {
      this.loaderService.hide();
      if (response != 'undefined' && response.status === 204) {
        this.alertService.success('Email sent successfully', this.options);
      } else {
        this.alertService.error(
          'Something went wrong - Please try again later.',
          this.options
        );
      }
    });
  }
}
