import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  AttributeInputCallback,
  CallbackType,
  Config,
  FRAuth,
  FRStep,
} from '@forgerock/javascript-sdk';
import { AlertService } from 'src/app/_alert';
import { DataShare } from 'src/app/common/datashare';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bap-forgot-userid',
  templateUrl: './bap-forgot-userid.component.html',
  styleUrls: ['./bap-forgot-userid.component.css'],
})
export class BapForgotUseridComponent {
  email: string = '';
  bapUserForm!: FormGroup;
  selected: any = 0;
  tree: any;
  currentStep: any;
  submitted = false;
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$';
  previousCallback: any;
  loginErrorMsg: boolean = false;
  displayErrorMsg: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
    timeout: 30000,
  };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataShare: DataShare,
    private alertService: AlertService
  ) {
    Config.set({
      serverConfig: {
        baseUrl: environment.AM_URL,

        timeout: 10000,
      },
      tree: this.tree ? this.tree : environment.FORGOT_USERID_TREE,
      realmPath: environment.REALM_PATH,
    });
  }
  ngOnInit() {
    this.currentStep = null;
    this.nextStep(this.dataShare.step);
    this.bapUserForm = this.formBuilder.group({
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[A-Za-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,3}$/),
      ]),
    });
  }
  get userForm(): { [key: string]: AbstractControl } {
    return this.bapUserForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    console.log('this.bapUserForm', this.bapUserForm.status);
    if (this.bapUserForm.invalid) {
      return;
    } else {
      this.setCallBack();
    }
  }
  closeAlert() {
    this.loginErrorMsg = false;
  }
  onReturnToLogin() {
    this.alertService.clear();
    this.router.navigate(['/producer/login']);
  }
  setCallBack() {
    let i = 0;
    this.currentStep.callbacks.forEach((callback: any) => {
      i++;
      switch (callback.getType() as CallbackType) {
        case CallbackType.StringAttributeInputCallback: {
          if (callback.payload.input[0].name === 'IDToken1') {
            (callback as AttributeInputCallback<any>).setInputValue(
              this.bapUserForm.value.email
            );
          }

          break;
        }
        case CallbackType.NameCallback: {
          if (callback.payload.input[0].name === 'IDToken1') {
            (callback as AttributeInputCallback<any>).setInputValue(
              this.bapUserForm.value.email
            );
          }

          break;
        }

        default: {
          console.error('Unrecognized callback type.');
          break;
        }
      }
    });
    //console.log('send response', this.currentStep);
    this.nextStep(this.currentStep);
  }

  async nextStep(step: FRStep) {
    this.previousCallback = step;
    this.currentStep = await FRAuth.next(step);

    this.currentStep.callbacks?.forEach((callback: any, index: any) => {
      if (callback.getType() === 'StringAttributeInputCallback' && index == 0) {
        // this.setCallBack()
      }
    });

    this.currentStep.callbacks?.forEach((callback: any, index: any) => {
      if (callback.getType() === 'TextOutputCallback' && index == 0) {
        this.selected = 1;
        this.alertService.success(
          `<span class="circleCheck-icon"></span>` +
            callback?.payload.output[0].value,
          this.options
        );
      }
    });
    //error handling in case of login failure
    if (this.currentStep.type === 'LoginSuccess') {
      this.selected = 1;
      this.setCallBack();
    }
    if (this.currentStep.type === 'LoginFailure') {
      if (
        this.currentStep?.payload?.message ===
        'Login Failed - Email is not verified'
      ) {
        this.resendEmail();
      } else {
        this.alertService.error(
          `<span class="circle-exclamation-icon"></span>` +
            this.currentStep?.payload?.message,
          this.options
        );
        this.onReturnToLogin();
      }
      this.currentStep = this.previousCallback;
    }
  }
  resendEmail() {}
}
