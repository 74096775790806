import { Component, Renderer2 } from '@angular/core';
import { MainService } from '../main.service';
import { AlertService } from 'src/app/_alert';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.css'],
})
export class ConfirmationPageComponent {
  confirmationType: string = '';
  verificationDone: boolean = false;

  userName: string = '';
  verificationCode: string = '';
  corpCode: string = '';
  appName: string = '';
  isLoading$ = this.loaderService.isLoading$;

  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };

  constructor(
    private mainService: MainService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setBackgroundColor('#ebf1f6');
    this.loaderService.show();
    this.route.queryParams.subscribe((params) => {
      this.userName = params['userName'] || 'defaultUserName';
      this.verificationCode =
        params['verificationCode'] || 'defaultVerificationCode';
      this.corpCode = params['corpCode'] || 'defaultCorpCode';
      this.appName = params['appName'] || 'defaultAppName';
    });
    this.verifyEmail();
  }

  emailSentSuccess() {
    this.confirmationType = 'emailSent';
  }

  setBackgroundColor(color: string): void {
    const routerWrapper = this.renderer.selectRootElement('#router-wrapper', true);
    this.renderer.setStyle(routerWrapper, 'backgroundColor', color);
  }

  verifyEmail() {
    let reqobj: any = {
      userName: this.userName,
      verificationCode: this.verificationCode,
      corpCode: this.corpCode,
      appName: this.appName,
    };

    this.mainService
      .postApiCall(reqobj, environment.BAP_VERIFY_Email_API)
      .pipe(
        tap((response: any) => {
          this.loaderService.hide();
          if (response != 'undefined' && response.status === 204) {
            this.router.navigate(['/producer/verification-done']);
          } else {
            this.verificationDone = true;
            this.alertService.error(
              'Verification Failed - Email is not verified.',
              this.options
            );
          }
        }),
        catchError((error) => {
          this.loaderService.hide();
          console.log('error', error);
          this.verificationDone = true;
          const errorMessage = error.error?.errors[0].message
            ? error.error?.errors[0].message
            : error.error?.message
            ? error.error.message
            : 'Verification Failed - Email is not verified.';
          this.alertService.error(errorMessage, this.options);
          return of(null); // Return an Observable with a null value
        })
      )
      .subscribe();
  }

  resendEmail() {
    let reqobj: any = {
      userName: this.userName,
      appName: this.appName,
      corpCode: this.corpCode,
      resendVerification: true,
    };

    let url =
      environment.BAP_RESEND_RESEND_Verification_Email +
      '/custom/resend-verification-email';
    this.loaderService.show();
    this.mainService.postApiCall(reqobj, url).subscribe((response: any) => {
      this.loaderService.hide();
      if (response != 'undefined' && response.status === 204) {
        this.emailSentSuccess();
      } else {
        this.alertService.error(
          'Something went wrong - Please try again later.',
          this.options
        );
      }
    });
  }

  ngOnDestroy() {
    this.setBackgroundColor('#ffffff');
  }
}
