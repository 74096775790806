import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  FRAuth,
  Config,
  FRStep,
  AttributeInputCallback,
  ChoiceCallback,
  DeviceProfileCallback,
  FRDevice,
  HiddenValueCallback,
} from '@forgerock/javascript-sdk';
import {
  CallbackType,
  NameCallback,
  PasswordCallback,
} from '@forgerock/javascript-sdk';
import { DataShare } from 'src/app/common/datashare';
import { UserSessionService } from 'src/app/common/user-session/user-session.service';
import { MainService } from 'src/app/common/main.service';
import { AlertService } from 'src/app/_alert';
import { LoaderService } from '../../shared/loader/loader.service';

@Component({
  selector: 'app-bap-login',
  templateUrl: './bap-login.component.html',
  styleUrls: ['./bap-login.component.css'],
})
export class BapLoginComponent {
  otpCode: any;
  user: any;
  currentStep: any;
  cStep!: number;
  choiceCB: any;
  codeType: any;
  previousCallback: any;
  typingDNAJS: any;
  deviceDetails: any;
  devicePrint: any = {};
  tree!: any;
  loginForm!: FormGroup;
  showPassword: boolean = false;
  showAlert: boolean = true;
  isLoading$ = this.loaderService.isLoading$;
  stepIs: any = '';
  nameCallBackStep: any;
  ismulti: boolean = true;
  sendCodeform!: FormGroup;
  mfa: boolean = false;
  singleVal = '';
  email: any;
  phone: any;
  saveDevice: number = 0;
  displayDNAOption: boolean = false;
  displayEmailOption: boolean = false;
  displayPhnOption: boolean = false;
  emailIndexVal: number = 0;
  displayErrorMsg!: string;
  phoneIndexVal: number = 0;
  dnaIndexValue: number = 0;
  successMsg: boolean = false;
  submitted: boolean = false;
  loginErrorMsg: boolean = false;
  authnForm: FormGroup;
  username: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
    timeout: 30000,
  };
  setResendORReject!: number;
  incorrectOTP: boolean = false;
  @ViewChild('otpInput') otpInput: ElementRef = new ElementRef('otpInput');

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataShare: DataShare,
    private userSession: UserSessionService,
    private mainService: MainService,
    private alertService: AlertService,
    private loaderService: LoaderService
  ) {
    this.authnForm = formBuilder.group({});

    Config.set({
      serverConfig: {
        baseUrl: environment.AM_URL,

        timeout: 10000,
      },
      tree: this.tree ? this.tree : environment.DEFAULT_TREE,
      realmPath: environment.REALM_PATH,
    });
  }

  ngOnInit() {
    console.log('checking environment', environment.NODE_ENV);
    this.stepIs = 'login';
    this.loaderService.show();
    this.getScrennData();
    this.nextStep(this.dataShare.step);
    this.loaderService.hide();
    this.loginForm = this.formBuilder.group({
      userId: ['', [Validators.minLength(4), Validators.maxLength(40)]],
      password: ['', [Validators.minLength(8), Validators.maxLength(40)]],
    });
    this.sendCodeform = this.formBuilder.group({
      sendCodeType: new FormControl('email', Validators.required),
    });
  }

  changeStep(newStep: string) {
    // Clear the alert whenever the step changes
    this.alertService.clear();
    this.closeAlert();
    // Logic to change the step
    this.stepIs = newStep;
  }

  // convenience getter for easy access to form fields
  get userLoginForm(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  onClickForgotPwd() {
    this.router.navigateByUrl('/producer/forgot-password');
  }

  onClickForgotUser() {
    this.router.navigateByUrl('/producer/forgot-user');
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.setCallBack();
    }

    this.submitted = false;
  }

  onOtpChange(code: any) {
    code.preventDefault();
    this.otpCode = code.target.value.replace(/\s/g, '');
    console.log('this.otpCode', this.otpCode);
  }

  async nextStep(step: FRStep): Promise<FRStep> {
    this.loaderService.show();
    console.log('Step paramater in nextStep(): ', step);
    this.previousCallback = step;
    this.currentStep = await FRAuth.next(step);
    this.loaderService.hide();
    // Call for deviceprofile callback
    this.currentStep.callbacks?.forEach((callback: any, index: number) => {
      if (callback.getType() === 'DeviceProfileCallback') {
        (callback as DeviceProfileCallback).setInputValue(
          JSON.stringify(this.deviceDetails)
        );
        this.nextStep(this.currentStep);
      }
      if (callback.getType() === 'ChoiceCallback') {
        if (
          callback.payload.output[0].value ===
          'The one-time verification code is incorrect. Do you want to resend?'
        ) {
          this.incorrectOTP = true;
          this.loaderService.hide();
          this.alertService.error(
            `<span class="circle-exclamation-icon"></span>` +
              "The one-time verification code you entered is incorrect. Enter the correct code or click the 'resend it' link to receive another code",
            this.options
          );
        } else if (
          callback.payload.output[0].value ===
          'Do you want to add this device as saved device?'
        ) {
          (callback as ChoiceCallback).setInputValue(this.saveDevice);
          console.log('ChoiceCallback saveDevice', this.saveDevice);
          callback.payload.input[0].value = this.saveDevice;
          this.nextStep(this.currentStep);
        }

        if (
          this.currentStep.payload.callbacks[0].output[0].value ===
          'Enter the OTP Method'
        ) {
          this.changeStep('verify');
          this.mfa = true;
          if (this.mfa) {
            callback.payload.input[0].value = 0;
          } else {
            callback.payload.input[0].value = 1;
          }

          if (
            this.currentStep?.payload?.callbacks[0]?.output[1].value.length > 1
          ) {
            this.ismulti = true;
            let optionsArray =
              this.currentStep?.payload?.callbacks[0]?.output[1].value;
            optionsArray.forEach((ele: any) => {
              let i = optionsArray.indexOf(ele);
              if (ele.includes('Email')) {
                this.emailIndexVal = i;
                this.email =
                  this.currentStep?.payload?.callbacks[0]?.output[1]?.value[
                    i
                  ]?.split(':')[1];
                this.displayEmailOption = true;
              }
              if (ele.includes('Mobile')) {
                this.phoneIndexVal = i;
                this.phone =
                  this.currentStep?.payload?.callbacks[0]?.output[1]?.value[
                    i
                  ]?.split(':')[1];
                this.displayPhnOption = true;
              }
              if (ele.includes('TypingDNA')) {
                this.dnaIndexValue = i;
                this.displayDNAOption = true;
              }
            });
            this.loaderService.hide();
          } else {
            this.ismulti = false;
            this.singleVal =
              this.currentStep?.payload?.callbacks[0]?.output[1].value[0];
            this.loaderService.hide();
          }
        }
      }
      if (callback.getType() === 'TextOutputCallback') {
        let callBackLen = this.currentStep?.callbacks.length;
        if (callback?.getMessageType() == 4) {
          console.log('print ', callback.getMessageType());
        }
        if (callBackLen == 7) {
          this.typingDNAJS =
            this.currentStep?.callbacks[3]?.payload.output[0]?.value;
        } else if (callBackLen == 8) {
          this.typingDNAJS =
            this.currentStep?.callbacks[4]?.payload.output[0]?.value;
        } else {
          this.typingDNAJS = '';
        }
        this.userSession.setScript(this.typingDNAJS);
        // console.log(this.typingDNAJS, 'typingDNAJS')
        let result = this.currentStep?.callbacks[0]?.payload.output[0]?.value;
        if (result === 'Please type the text below (typos allowed):') {
          this.changeStep('typingDna');
        } else {
          result = JSON.parse(result);
        }
        this.dataShare.step = this.previousCallback;
        console.log('Data share step in nextStep()', this.dataShare.step);
        // this.router.navigate(['/producer/update-password']);
        this.loaderService.hide();
      }
    });
    //error handling in case of login failure
    console.log('currentStep in nextStep(): ', this.currentStep);
    if (this.currentStep.type === 'LoginSuccess') {
      this.setCallBack();
    }
    if (this.currentStep.type === 'LoginFailure') {
      if (
        this.currentStep?.payload?.message ===
        'Login Failed - Email is not verified'
      ) {
        this.resendEmail();
      } else {
        this.loaderService.hide();
        this.onReturnToLoginPage();
        this.alertService.error(
          `<span class="circle-exclamation-icon"></span>` +
            this.currentStep?.payload?.message,
          this.options
        );
      }
    }
   return Promise.resolve(this.currentStep);
  }

  backToStep2() {
    this.changeStep('verify');
    this.currentStep = this.choiceCB;
  }

  backToStep1() {
    this.currentStep = this.nameCallBackStep;
    this.changeStep('login');
  }

  backToStep3() {}

  handleSelection() {
    this.codeType = this.sendCodeform.controls.sendCodeType.value;
    this.choiceCallback(this.currentStep, this.codeType);
  }

  onCreateClick() {
    this.router.navigate(['/producer/terms-conditions']);
  }

  resendEmail() {
    this.username = this.loginForm.value.userId;
    console.log('username:' + this.username);
    this.mainService.resendBAPEmail(this.username).subscribe((data: any) => {
      this.loaderService.hide();
      if (data != 'undefined' && data === 'Success') {
        this.alertService.error(
          `<span class="circle-exclamation-icon"></span>` +
            'Email is not verified.<br/> We are sending you a new email to verify your email address.',
          this.options
        );
      } else {
        this.alertService.error(
          'Login Failed - Email is not verified.<br/> For assistance, call our Internet Help Desk at 1-888-706-0583',
          this.options
        );
      }
    });
  }

  onReturnToLoginPage() {
    this.changeStep('login');
  }

  onContinueNext() {
    this.setCallBack();
    // this.successMsg = true
  }

  handleResend() {
    this.setResendORReject = 0;
    this.choiceCallback(this.choiceCB, this.codeType).then((res) => {
      console.log(res);
      this.incorrectOTP = false;
      this.otpInput.nativeElement.value = '';
      this.otpCode = '';
      this.alertService.success("A new code has been sent to the registered Email/Phone Number. Please check and enter it on this screen.",this.options)
    });
  }

  handleReject() {
    this.setResendORReject = 1;
    this.choiceCallback(this.choiceCB, this.codeType).then((res) => {
      console.log(res);
    });
  }

  closeAlert() {
    this.showAlert = false;
  }

  async choiceCallback(step: FRStep, codeSelected: string) : Promise<FRStep> {
    this.choiceCB = step;
    this.loaderService.show();
    step.callbacks?.forEach((callback: any, index) => {
      if (callback.getType() === 'ChoiceCallback') {
        if (
          callback.payload.output.length == 1 &&
          callback.payload.output.values[0] === 'Resend'
        ) {
          (callback as ChoiceCallback).setInputValue(this.setResendORReject);
        } else if (callback.payload.input[0].name === 'IDToken1') {
          let optedType;
          if (codeSelected == 'email') {
            optedType = this.emailIndexVal;
            this.changeStep('otp');
          } else if (codeSelected == 'phone') {
            optedType = this.phoneIndexVal;
            this.changeStep('otp');
          } else {
            optedType = this.dnaIndexValue;
            this.changeStep('typingDna');
          }
          callback.payload.input[0].value = optedType;
        } else if (
          callback.payload.output[0].value ===
          'Do you want to add this device as saved device?'
        ) {
          (callback as ChoiceCallback).setInputValue(this.saveDevice);
          console.log('ChoiceCallback saveDevice', this.saveDevice);
          callback.payload.input[0].value = this.saveDevice;
          this.nextStep(this.currentStep);
        }
      }
    });
   await this.nextStep(this.currentStep);
   return Promise.resolve(this.currentStep);
  }

  redirectToProducer() {
    this.alertService.clear();
    window.location.href = environment.BAP_FORWARD;
  }

  //other approach
  rememberDevice(e: any) {
    let checkDeviceSave = document.getElementById(
      'saveDeviceCheck'
    ) as HTMLInputElement;
    if (checkDeviceSave.checked) {
      this.saveDevice = 1;
    } else {
      this.saveDevice = 0;
    }
  }

  setCallBack() {
    this.loaderService.show();
    if (this.currentStep?.type === 'LoginSuccess') {
      this.redirectToProducer();
    } else {
      let i = 0;
      this.currentStep?.callbacks?.forEach((callback: any) => {
        i++;
        switch (callback.getType() as CallbackType) {
          case CallbackType.NameCallback: {
            this.nameCallBackStep = this.currentStep;
            (callback as NameCallback).setName(
              `${this.loginForm.value.userId}`
            );
            this.user = this.loginForm.value.userId;
            if (
              callback?.payload?.output[0]?.value === 'Enter one time password'
            ) {
              if (this.otpCode == null || this.otpCode == '') {
                this.otpCode = '000000000';
              }
              (callback as NameCallback).setInputValue(this.otpCode);
            }
            this.loaderService.hide();
            break;
          }

          case CallbackType.PasswordCallback: {
            if (callback?.payload?.output[0]?.value === 'One Time Password') {
              if (this.otpCode == null || this.otpCode == '') {
                this.otpCode = '000000000';
              }
              (callback as PasswordCallback).setPassword(this.otpCode);
            } else {
              (callback as PasswordCallback).setPassword(
                this.loginForm.value.password
              );
            }
            this.loaderService.hide();
            break;
          }

          case CallbackType.ChoiceCallback: {
            if (
              callback.payload.output[0].value ===
              'The one-time verification code is incorrect. Do you want to resend?'
            ) {
              /**make true to disable submit */
              this.incorrectOTP = true;

              console.log(
                'setCallback ChoiceCallback: ',
                callback.payload.output[0].value
              );

              this.alertService.error(
                `<span class="circle-exclamation-icon"></span>` +
                  "The one-time verification code you entered is incorrect. Enter the correct code or click the 'resend it' link to receive another code",
                this.options
              );
            } else {
              if (callback.payload.input[0].name === 'IDToken1') {
                this.cStep = this.currentStep;
              } else {
              }
              if (
                callback.payload.output[0].value ===
                'Do you want to add this device as saved device?'
              ) {
                (callback as ChoiceCallback).setInputValue(this.saveDevice);
                console.log('ChoiceCallback saveDevice', this.saveDevice);
                callback.payload.input[0].value = this.saveDevice;
              }
            }
            this.loaderService.hide();
            break;
          }
          case CallbackType.HiddenValueCallback: {
            var value = (<HTMLInputElement>document.getElementById('TP')).value;
            if (value == '') {
              value = 'TP';
            }
            var value2 = (<HTMLInputElement>document.getElementById('TEXT_ID'))
              .value;

            if (callback.payload.output[1].value === 'DEVICE_TYPE') {
              (callback as HiddenValueCallback).setInputValue('0');
            } else {
              callback.payload.output[1].value === 'TEXT_ID'
                ? (callback as HiddenValueCallback).setInputValue(value2)
                : (callback as HiddenValueCallback).setInputValue(value);
            }
            this.loaderService.hide();
            break;
          }
          default: {
            this.loaderService.hide();
            console.error('Unrecognized callback type.');
            break;
          }
        }
      });
      this.loaderService.hide();
      this.nextStep(this.currentStep);
    }
  }

  //get device details
  collectScreenInfo = function () {
    var screenInfo: any = {};
    if (screen) {
      if (screen.width) {
        screenInfo.screenWidth = screen.width;
      }

      if (screen.height) {
        screenInfo.screenHeight = screen.height;
      }

      if (screen.pixelDepth) {
        screenInfo.screenColourDepth = screen.pixelDepth;
      }
    } else {
      console.warn('Cannot collect screen information. screen is not defined.');
    }
    return screenInfo;
  };
  collectTimezoneInfo = function () {
    var timezoneInfo: any = {},
      offset = new Date().getTimezoneOffset();

    if (offset) {
      timezoneInfo.timezone = offset;
    } else {
      console.warn(
        'Cannot collect timezone information. timezone is not defined.'
      );
    }

    return timezoneInfo;
  };
  collectBrowserPluginsInfo = function () {
    if (navigator && navigator.plugins) {
      var pluginsInfo: any = {},
        i,
        plugins = navigator.plugins;
      pluginsInfo.installedPlugins = '';

      for (i = 0; i < plugins.length; i++) {
        pluginsInfo.installedPlugins =
          pluginsInfo.installedPlugins + plugins[i].filename + ';';
      }

      return pluginsInfo;
    } else {
      console.warn(
        'Cannot collect browser plugin information. navigator.plugins is not defined.'
      );
      return {};
    }
  };

  // Getting geoloation takes some time and is done asynchronously, hence need a callback which is called once geolocation is retrieved.
  collectGeolocationInfo() {
    var geolocationInfo: any = {},
      successCallback = function (position: any) {
        geolocationInfo.longitude = position?.coords?.longitude;
        geolocationInfo.latitude = position?.coords?.latitude;
      },
      errorCallback = function (error: any) {
        console.warn(
          'Cannot collect geolocation information. ' +
            error.code +
            ': ' +
            error.message
        );
      };
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.warn(
        'Cannot collect geolocation information. navigator.geolocation is not defined.'
      );
    }
  }

  collectBrowserFontsInfo() {
    var fontsInfo: any = {},
      i,
      fontsList = [
        'cursive',
        'monospace',
        'serif',
        'sans-serif',
        'fantasy',
        'default',
        'Arial',
        'Arial Black',
        'Arial Narrow',
        'Arial Rounded MT Bold',
        'Bookman Old Style',
        'Bradley Hand ITC',
        'Century',
        'Century Gothic',
        'Comic Sans MS',
        'Courier',
        'Courier New',
        'Georgia',
        'Gentium',
        'Impact',
        'King',
        'Lucida Console',
        'Lalit',
        'Modena',
        'Monotype Corsiva',
        'Papyrus',
        'Tahoma',
        'TeX',
        'Times',
        'Times New Roman',
        'Trebuchet MS',
        'Verdana',
        'Verona',
      ];
    fontsInfo.installedFonts = '';

    return fontsInfo;
  }

  async getScrennData() {
    this.devicePrint.screen = this.collectScreenInfo();
    this.devicePrint.timezone = this.collectTimezoneInfo();
    this.devicePrint.plugins = this.collectBrowserPluginsInfo();
    this.devicePrint.fonts = this.collectBrowserFontsInfo();

    if (navigator.userAgent) {
      this.devicePrint.userAgent = navigator.userAgent;
    }
    if (navigator.appName) {
      this.devicePrint.appName = navigator.appName;
    }
    if (navigator.appCodeName) {
      this.devicePrint.appCodeName = navigator.appCodeName;
    }
    if (navigator.appVersion) {
      this.devicePrint.appVersion = navigator.appVersion;
    }
    if (navigator.platform) {
      this.devicePrint.platform = navigator.platform;
    }
    if (navigator.product) {
      this.devicePrint.product = navigator.product;
    }
    if (navigator.productSub) {
      this.devicePrint.productSub = navigator.productSub;
    }
    if (navigator.vendor) {
      this.devicePrint.vendor = navigator.vendor;
    }
    if (navigator.vendorSub) {
      this.devicePrint.vendorSub = navigator.vendorSub;
    }
    if (navigator.language) {
      this.devicePrint.language = navigator.language;
    }
    if (navigator.languages) {
      this.devicePrint.userLanguage = navigator.languages;
    }

    const device = new FRDevice();
    //let loc = await device.getLocationCoordinates()
    let deviceMeta = await device.getDisplayMeta();
    let deviceInfo = {
      identifier: device.getIdentifier(),
      metadata: {
        hardware: {
          cpuClass: null,
          deviceMemory: 8,
          hardwareConcurrency: navigator.hardwareConcurrency,
          maxTouchPoints: navigator.maxTouchPoints,
          oscpu: null,
          display: {
            width: deviceMeta.width,
            height: deviceMeta.height,
            pixelDepth: deviceMeta.pixelDepth,
            angle: deviceMeta.angle,
          },
        },
        browser: {
          userAgent: navigator.userAgent,
          appName: navigator.appName,
          appCodeName: navigator.appCodeName,
          appVersion: navigator.appVersion,
          appMinorVersion: null,
          buildID: null,
          product: navigator.product,
          productSub: navigator.productSub,
          vendor: navigator.vendor,
          vendorSub: '',
          browserLanguage: null,
          plugins: this.devicePrint.plugins.installedPlugins,
        },
        platform: {
          deviceName: device.getDeviceName(),
          language: navigator.language,
          platform: this.devicePrint.platform,
          userLanguage: null,
          systemLanguage: null,
          fonts: this.devicePrint.fonts.installedFonts,
          timezone: this.devicePrint.timezone.timezone,
        },
      },
    };
    this.deviceDetails = deviceInfo;
  }
}
