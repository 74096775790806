<div class="flex-center">
  <div class="card tablecard">
    <div class="card-header padding5 flex-center">
      <div class="flex-center">
        <img
          src="/assets/img/bcbs-5-plan-logo-rgb-states-only.svg"
          alt="logo"
        />
      </div>
      <div class="card-text flex-center">
        <p class="tittle">Verification Complete!</p><br>
        <p class="sub-tittle">You have successfully verified your email address</p>
      </div>
      
      <div>
        <button (click)="onClickToLogin()"
          class="fiber-button fiber-button--primary custom-btn fiber-text--weight-bold"
        >
          <span class="fiber-button__text">Log In</span>
        </button>
      </div>
    </div>
  </div>
</div>
