import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verification-success',
  templateUrl: './verification-success.component.html',
  styleUrls: ['./verification-success.component.css'],
})
export class VerificationSuccessComponent {
  constructor(private router: Router, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setBackgroundColor('#ebf1f6');
  }

  onClickToLogin() {
    this.router.navigate(['/producer/login']);
  }

  setBackgroundColor(color: string): void {
    const routerWrapper = this.renderer.selectRootElement(
      '#router-wrapper',
      true
    );
    this.renderer.setStyle(routerWrapper, 'backgroundColor', color);
  }

  ngOnDestroy() {
    this.setBackgroundColor('#ffffff');
  }
}
