<block-ui>
  <app-bap-header></app-bap-header>
  <main>
    <alert></alert>
    <div id="router-wrapper">
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-bap-footer></app-bap-footer>
</block-ui>
